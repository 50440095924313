import React, { CSSProperties, forwardRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Icon, Popup, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import util from "utils/utils";

const StyledTitle = styled.div<{ $inverted?: boolean; $compact?: boolean | "very" }>`
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${({ $compact }) => ($compact ? ($compact === "very" ? "inherit" : "bold") : "bold")} !important;

  font-size: ${({ $compact }) => ($compact ? ($compact === "very" ? "1em" : "12px") : "13px")} !important;
  line-height: ${({ $compact }) => ($compact ? ($compact === "very" ? "1lh" : "14px") : "15px")} !important;
  text-decoration: none !important;

  width: fit-content;
  max-width: 100%;
  position: relative;
  margin-right: ${({ $compact }) => ($compact === "very" ? "0" : "4px")};

  ${({ $inverted, $compact }) =>
    $inverted
      ? "color: white !important;"
      : $compact === "very"
        ? "color: inherit !important"
        : `color: black !important;`}
`;

// Flex when not very compact to correctly position icon avatars
const StyledAvatar = styled.div<{
  $compact?: boolean | "very";
  $imageUrl: string;
  $borderRadius?: number;
}>`
  display: ${({ $compact }) => ($compact === "very" ? "inline-block" : "flex")};
  justify-content: center;
  align-items: center;

  vertical-align: top;
  transform: ${({ $compact }) => ($compact === "very" ? "translateY(25%)" : "none")};

  height: ${({ $compact }) => ($compact ? ($compact === "very" ? "1em" : "25px") : "35px")};
  width: ${({ $compact }) => ($compact ? ($compact === "very" ? "1em" : "25px") : "35px")};
  min-height: ${({ $compact }) => ($compact ? ($compact === "very" ? "1em" : "25px") : "35px")};
  min-width: ${({ $compact }) => ($compact ? ($compact === "very" ? "1em" : "25px") : "35px")};
  border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}px` : "50%")};
  background-color: white;
  background-size: cover;
  background-position: center;
  margin-right: ${({ $compact }) => ($compact === "very" ? "4px" : "8px")};
  ${({ $imageUrl }) =>
    $imageUrl
      ? `background-image: url(${util.mixinCssUrlFallback($imageUrl, util.avatarUrl())});`
      : "background: #ededed;"};
  box-shadow: ${({ $compact }) => ($compact === "very" ? "none" : "0 0 4px rgba(0, 0, 0, 0.2)")};
`;

const StyledChip = styled.div<{ $inverted?: boolean; $withLink?: boolean; $compact: boolean | "very" }>`
  display: ${({ $compact }) => ($compact === "very" ? "inline" : "inline-flex")};
  align-items: center;
  max-width: 100%;

  border-radius: 50px;
  padding: ${({ $compact }) => ($compact === "very" ? "0px" : "3px 12px 3px 3px")};

  ${({ $inverted }) =>
    $inverted &&
    `
    background: rgba(0, 3, 45, 0.3);
  `}
  transition: background 0.1s, box-shadow 0.1s;

  ${({ $withLink, $inverted, $compact }) =>
    $withLink && $compact !== "very"
      ? `
      &:hover {
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, .1), 1px 1px 1px 0 ${$inverted ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.1)"};

        ${StyledAvatar} {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
        }
      }
      &:active {
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, .05) inset, 0 1px 2px 0 rgba(0, 0, 0, 0.15) inset;
        background: ${$inverted ? "rgba(0, 3, 45, 0.37)" : "rgba(0, 0, 0, 0.04)"};

        ${StyledAvatar} {
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
        }
      }
    `
      : ""}
`;

// The max-width of this container is calculated by removing the size of the user image and its margin
const ChipTextContainer = styled.div`
  display: inline-flex;
  flex-direction: column;

  text-wrap: nowrap;
  min-width: 0px;
  max-width: calc(100% - 4px - 1em);
  flex: 1 1 auto;
`;

const StyledPostText = styled.span<{ $inverted?: boolean }>`
  cursor: default;
  font-size: 12px !important;
  vertical-align: middle;
  color: black;
`;

const StyledDescription = styled.p<{ $inverted?: boolean; $compact?: boolean | "very" }>`
  ${({ $compact }) => ($compact ? "font-size: 10px !important;" : "font-size: 11px !important;")}
  line-height: 12px;
  min-height: 10px;
  min-width: 1px;
  text-overflow: ellipsis;
  overflow: hidden;

  font-weight: normal !important;

  ${({ $inverted }) => ($inverted ? "color: white !important;" : `color: black !important;`)}
  margin: 0px !important;
`;

const ActionIcon = styled(Icon)<{ $inverted?: boolean; $compact?: boolean | "very" }>`
  &&&& {
    color: ${({ $inverted }) => ($inverted ? "white" : "gray")};
    margin: 0;
    font-size: 1.2em;
    margin-left: 0px;
    margin-right: -8.75px;
    cursor: pointer;
    padding: ${({ $compact }) => ($compact ? "12px" : "18px")};
    border-radius: 50%;
    position: relative;

    &::before {
      position: absolute;
      top: ${({ $compact }) => ($compact ? "3px" : "9px")};
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:hover {
      background: ${({ inverted }) => (inverted ? "white" : "gray")};
      color: ${({ inverted }) => (inverted ? "gray" : "white")};
    }
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 300px;
`;

const PopupLargerImage = styled.div<{
  $imageUrl: string;
  $borderRadius?: number;
}>`
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryColour};

  border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}px` : "50%")};
  background-color: white;
  background-size: cover;
  background-position: center;
  margin-right: 10px;

  ${({ $imageUrl }) =>
    $imageUrl ? `background-image: url(${$imageUrl}), url(${util.avatarUrl()});` : "background: #ededed;"};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

const PopupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export type ChipConfiguration = {
  style?: CSSProperties;
  borderRadius?: number;
  inverted?: boolean;
  description?: string;
  details?: string;
  labels?: { name?: string; icon?: { name?: string; colour?: string } }[];

  precedingText?: string;
  postText?: string;
  linkTo?: string;
  target?: "_blank";

  onClick?: (e) => void;
  compact?: boolean | "very";
  actionIcon?: SemanticICONS;
  actionClick?: () => void;
  actionTooltip?: string;
};

type BaseChipProps = ({ imageUrl: string; icon?: SemanticICONS } | { imageUrl?: string; icon: SemanticICONS }) & {
  title: string;
} & ChipConfiguration;

const BaseChip = forwardRef(
  (
    {
      imageUrl,
      icon,
      borderRadius,
      style,
      inverted,
      title,
      description,
      details,
      labels,

      precedingText,
      postText,
      linkTo,
      onClick,
      compact,
      target,
      actionIcon,
      actionClick,
      actionTooltip,
    }: BaseChipProps,
    ref,
  ) => {
    const handleClickWithDefault = useCallback(
      (e) => {
        if (onClick) {
          e.stopPropagation();
          e.preventDefault();
          onClick(e);
        }
      },
      [onClick],
    );

    return (
      <Popup
        wide="very"
        on="hover"
        basic
        trigger={
          <Link
            ref={ref}
            style={{
              display: "inline-flex",
              alignItems: "center",
              minWidth: 0,
              whiteSpace: "nowrap",
              color: "inherit",
              maxWidth: "100%",
              verticalAlign: compact === "very" ? "top" : "middle",
              cursor: linkTo || onClick ? "pointer" : "default",
              ...(style || {}),
            }}
            to={linkTo ?? "#"}
            target={target}
          >
            <StyledChip
              onClick={handleClickWithDefault}
              $compact={compact}
              $inverted={!!inverted}
              $withLink={!!linkTo || !!onClick}
            >
              <StyledAvatar $imageUrl={imageUrl} $compact={compact} $borderRadius={borderRadius}>
                {!imageUrl && icon ? (
                  <Icon
                    name={icon}
                    style={
                      compact === "very"
                        ? { margin: 0, verticalAlign: "top", marginLeft: 1, marginTop: 2 }
                        : { marginTop: -5, marginLeft: 2 }
                    }
                    size={compact === "very" ? "small" : undefined}
                  />
                ) : null}
              </StyledAvatar>
              <ChipTextContainer>
                {compact !== "very" && precedingText ? (
                  <StyledDescription $inverted={!!inverted}>{precedingText}</StyledDescription>
                ) : null}
                <StyledTitle $inverted={inverted} $compact={compact}>
                  {title}
                </StyledTitle>
                {compact !== "very" && description ? (
                  <StyledDescription $inverted={inverted} $compact={compact}>
                    {description}
                  </StyledDescription>
                ) : null}
              </ChipTextContainer>
              {compact !== "very" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginLeft: 4,
                    maxHeight: 30,
                    rowGap: 3,
                    minWidth: 0,
                    overflow: "hidden",
                    flex: "1 1 auto",
                  }}
                >
                  {labels?.map((label) =>
                    label.icon ? (
                      <Icon
                        key={`${label.name}-${label.icon?.name}-${label.icon?.colour}`}
                        name={label.icon.name as SemanticICONS}
                        color={label.icon.colour as SemanticCOLORS}
                        size="small"
                        // style={{
                        //   marginRight: 3,
                        //   position: "relative",
                        //   top: compact ? -2.5 : -3,
                        // }}
                      />
                    ) : null,
                  )}
                </div>
              ) : null}
              {compact !== "very" && actionIcon && actionClick ? (
                <Popup
                  disabled={!actionTooltip}
                  content={actionTooltip}
                  trigger={
                    <ActionIcon
                      name={actionIcon}
                      $inverted={inverted}
                      $compact={compact}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        actionClick();
                      }}
                    />
                  }
                />
              ) : null}
            </StyledChip>
            {postText && <StyledPostText $inverted={!!inverted}>{postText}</StyledPostText>}
          </Link>
        }
        content={
          <PopupContent>
            <PopupLargerImage $imageUrl={imageUrl} $borderRadius={borderRadius}>
              {!imageUrl && icon ? <Icon name={icon} size="big" style={{ marginLeft: 4 }} /> : null}
            </PopupLargerImage>
            <PopupInfo>
              <h3 style={{ marginBottom: 5, marginTop: 0, fontWeight: "bolder" }}>{title}</h3>
              {description ? <h5 style={{ marginBottom: 5, marginTop: 0 }}>{description}</h5> : null}
              {details ? (
                <StyledDescription>
                  <i>{details}</i>
                </StyledDescription>
              ) : null}
              {labels?.map((label) => (
                <span key={`${label.name}-${label.icon?.name}-${label.icon?.colour}`}>
                  {label.name}
                  {label.icon ? (
                    <Icon
                      name={label.icon.name as SemanticICONS}
                      color={label.icon.colour as SemanticCOLORS}
                      style={{ marginLeft: 5 }}
                    />
                  ) : null}
                </span>
              ))}
            </PopupInfo>
          </PopupContent>
        }
      />
    );
  },
);

BaseChip.displayName = "BaseChip";

export default BaseChip;
