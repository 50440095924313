/* eslint-disable */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Label, Dropdown, Popup, Modal, Loader, Checkbox } from "semantic-ui-react";
import { ChallengeFollowButton } from "components/lib/FollowButton";
import { Sticky } from "components/lib/UI";
import api from "api";
import util from "utils/utils";
import actions from "actions";
import toast from "react-hot-toast";
import { SearchParams } from "simplydo/core";

import AdjustableUserList from "./AdjustableUserList";
import ChallengeStageChanger from "./ChallengeStageChanger";
import { useAppSelector } from "store";

const StyledStatusBar = styled.div`
  height: ${({ theme }) => (!theme.sizes.isComputer ? 50 : 60)}px;
  padding: ${({ theme }) => (!theme.sizes.isComputer ? "10px 10px" : "10px 20px")};
  vertical-align: middle;
  background-image: ${(props) =>
    `linear-gradient(to bottom right, ${props.theme.secondaryColour}, ${util.adjustColour(props.theme.secondaryColour, 0.5)})`};
  top: 51px;
  width: 100%;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
`;

const ChallengeStatusBar = ({
  challenge = { stage: "published" },
  challengeId,
  completed: propCompleted,
  update,
  t,
  loading,
  newIdea,
  myIdeas,
  overviewPage,
  redirectToLogin,
}) => {
  const user = useAppSelector((state) => state.user);

  const [template, setTemplate] = useState({});
  const [requestToOpenChallengeOpen, setRequestToOpenChallengeOpen] = useState(false);
  const [selectedRequestAdmin, setSelectedRequestAdmin] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const [adminsLoading, setAdminsLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [contactingAdmin, setContactingAdmin] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const theme = useTheme();

  const params = new SearchParams(window.location.search);
  const shouldOpenChannel = params.get("shouldOpenChallenge", false);

  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);
  const canViewEntireChallenge = useMemo(() => util.canViewEntireChallenge(user, challenge), [user, challenge]);

  const getChallenge = useCallback(() => {
    if (canManageChallenge) {
      api.announcements.get("challenge", challengeId, null, (data) => setAnnouncements(data.automaticAnnouncements));
    }
    api.challenges.getTemplate(challengeId, (newTemplate) => setTemplate(newTemplate));
  }, [challengeId, canManageChallenge]);

  useEffect(() => getChallenge(), [getChallenge]);

  const userOrg = user?.organisation._id;
  const getAdmins = useCallback(() => {
    if (userOrg) {
      setAdminsLoading(true);
      api.organisations.getAdmins(
        userOrg,
        { limit: 10, permissions: ["org.publishChallenges"] },
        ({ users }) => {
          setAdmins(users);
          setAdminsLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setAdminsLoading(false);
        },
      );
    }
  }, [userOrg]);

  const contactAdmin = useCallback(() => {
    setContactingAdmin(true);
    api.organisations.contactAdmin(
      userOrg,
      { challenge: challengeId, contactType: "requestOpenChallenge", admin: selectedRequestAdmin },
      () => {
        toast.success("Request sent to admin");
        setSelectedRequestAdmin("");
        setContactingAdmin(false);
        setRequestToOpenChallengeOpen(false);
        setRequestSent(true);
      },
      (err) => {
        toast.error(err.message);
        setContactingAdmin(false);
      },
    );
  }, [selectedRequestAdmin, userOrg, challengeId]);

  useEffect(() => {
    if (requestToOpenChallengeOpen) {
      getAdmins();
    }
  }, [requestToOpenChallengeOpen, getAdmins]);

  const updateStage = useCallback(
    (newStage, openWithComms = false) => {
      const completed = { ...(propCompleted || {}) };
      if (!propCompleted) {
        completed.audienceCompleted = challenge.audienceSize > 0 ? "done" : false;
        completed.templateCompleted = template.body?.length > 0 ? "done" : false;

        const isDone = !!(
          challenge?.description?.length > 0 &&
          challenge?.description !== undefined &&
          challenge?.instructions?.length > 0 &&
          challenge?.instructions !== undefined &&
          challenge.bannerImage &&
          challenge.name
        );

        const isAlmost =
          challenge.description ||
          challenge.instructions ||
          challenge.sector ||
          challenge.video ||
          challenge.coverImage ||
          challenge.bannerImage;

        completed.detailsCompleted = isDone ? "done" : isAlmost ? "almost" : false;
        completed.announcementCompleted = announcements?.length > 0 ? "done" : false;
      }
      const updateStageFunction = () =>
        api.challenges.updateStage(challengeId, newStage, openWithComms, (s) => update(challengeId, s));
      if (
        !openWithComms &&
        newStage === "published" &&
        (completed.audienceCompleted !== "done" ||
          completed.templateCompleted !== "done" ||
          completed.announcementCompleted !== "done" ||
          completed.detailsCompleted !== "done")
      ) {
        util
          .confirm(
            `Really open this ${t("generic.challenge")}?`,
            `You have not completed all the recommened sections of the ${t("generic.challenge")}, are you sure you want to open the ${t("generic.challenge")}.`,
          )
          .then(
            () => {
              updateStageFunction();
            },
            () => {},
          )
          .catch(() => {});
      } else if (newStage === "closed") {
        util
          .confirm(
            `Really close this ${t("generic.challenge")}?`,
            `Are you sure you want to close this ${t("generic.challenge")}. Closing the ${t("generic.challenge")} will prevent any new ${t("generic.ideas")} or submissions.`,
          )
          .then(
            () => {
              updateStageFunction();
            },
            () => {},
          )
          .catch(() => {});
      } else updateStageFunction();
    },
    [announcements, template, challenge, challengeId, propCompleted, update],
  );

  const updateFollowerCount = useCallback(
    (increase = true) => {
      let { followerCount = 0 } = challenge;
      if (increase) followerCount += 1;
      else followerCount -= 1;
      update(challenge._id, { followerCount });
    },
    [challenge, update],
  );

  const pinChallenge = useCallback(() => {
    const { isPinned, _id } = challenge;
    api.challenges.setStatus(_id, { isPinned: !isPinned }, () => update(_id, { isPinned: !isPinned }));
  }, [update, challenge]);

  const challengeStage = challenge.stage;
  useEffect(() => {
    if (shouldOpenChannel && challengeStage !== "published") {
      updateStage("published");
    }
  }, [shouldOpenChannel, challengeStage, updateStage]);

  const canPublishChallenges = useMemo(
    () => util.hasPermission(user, "org.publishChallenges", user?.organisation?._id),
    [user],
  );
  const canMakeIdea = util.canCreateIdea(user, challenge);
  const myUnsubmittedIdeas = useMemo(() => myIdeas?.filter((idea) => !idea.isSubmitted), [myIdeas]);

  const updateStageButton = useMemo(() => {
    if (challenge?.stage === "published") {
      return (
        <Button
          size="tiny"
          disabled={!canPublishChallenges}
          secondary
          content={t("challenge.stages.published.close")}
        />
      );
    }
    if (canPublishChallenges) {
      if (!challenge?.stage) {
        return <Button size="tiny" primary content={t("challenge.stages.draft.open")} />;
      }
      if (challenge?.stage === "closed") {
        return <Button size="tiny" primary content={t("challenge.stages.closed.reOpen")} />;
      }
    }
  }, [challenge, canPublishChallenges, requestSent, t]);

  return (
    <Sticky top={50} style={{ zIndex: 90 }}>
      <StyledStatusBar theme={theme}>
        <Modal
          mountNode={document.getElementById("semantic-modal-mount-node")}
          open={requestToOpenChallengeOpen}
          onClose={() => setRequestToOpenChallengeOpen(false)}
        >
          <Modal.Header>Request to open {t("generic.challenge")}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                You do not have permission to open {t("generic.challenges")} in your organisation. Please select an
                admin, who will receive a request from you to open this {t("generic.challenge")}.
              </p>
            </Modal.Description>
            {adminsLoading ? (
              <Loader active />
            ) : (
              <div>
                <AdjustableUserList
                  results={admins}
                  actions={(adminUser) => (
                    <Checkbox
                      checked={selectedRequestAdmin === adminUser._id}
                      onClick={() => {
                        setSelectedRequestAdmin((prevId) => (prevId === adminUser._id ? "" : adminUser._id));
                      }}
                      size="large"
                    />
                  )}
                />
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button content="Cancel" icon="close" onClick={() => setRequestToOpenChallengeOpen(false)} />
            <Button
              color="green"
              icon="check"
              content="Send request"
              disabled={!selectedRequestAdmin}
              loading={contactingAdmin}
              onClick={contactAdmin}
            />
          </Modal.Actions>
        </Modal>

        <div style={{ gap: 4 }}>
          {theme.sizes.isComputer ? (
            <>
              {!challenge.stage && <Label color="yellow">{t("challenge.stages.draft.title")}</Label>}
              {challenge.stage === "published" && (
                <Label color="green" style={{ color: "#fff", marginRight: 18 }}>
                  {t("challenge.stages.published.title")}
                </Label>
              )}
              {challenge.stage === "closed" && (
                <Label color="red" style={{ color: "#fff", marginRight: 18 }}>
                  {t("challenge.stages.closed.title")}
                </Label>
              )}
            </>
          ) : null}

          {theme.sizes.isComputer && util.canViewEntireChallenge(user, challenge) && (
            <React.Fragment>
              <Button size="tiny" as="div" labelPosition="left">
                <Label basic>{challenge.ideaCount || 0}</Label>
                <Button
                  size="tiny"
                  as={Link}
                  to={`/challenges/${challenge._id}/ideas`}
                  icon="lightbulb"
                  content={t("common:capitalise", { key: "generic.ideas" })}
                  inverted
                  basic
                />
              </Button>

              <Button size="tiny" as="div" labelPosition="left">
                <Label basic>{challenge.audienceSize || 0}</Label>
                <Button
                  size="tiny"
                  as={Link}
                  inverted
                  basic
                  to={`/challenges/${challenge._id}/settings/audience`}
                  icon="users"
                  content={t("challenge.settings.audience.title")}
                />
              </Button>
            </React.Fragment>
          )}

          {util.hasPermission(user, "org.manageChallenges", user?.organisation?._id) && !theme.sizes.isMobile ? (
            <Popup
              content={`Pinned ${t("generic.challenges")} will show at the top of users' '${t("common:capitalise", { key: "generic.challenges" })}' page. They will also appear on the homepage`}
              on="hover"
              trigger={
                <Button
                  size="tiny"
                  inverted
                  basic
                  content={challenge.isPinned ? "Un-pin" : "Pin"}
                  icon="thumbtack"
                  onClick={pinChallenge}
                />
              }
            />
          ) : null}

          <ChallengeFollowButton
            challenge={challenge}
            onFollow={() => updateFollowerCount(true)}
            onUnfollow={() => updateFollowerCount(false)}
            buttonProps={{
              size: "tiny",
            }}
          />

          {!canManageChallenge && !canViewEntireChallenge && theme.sizes.isComputer && (
            <span style={{ color: "white", fontSize: 16, marginLeft: 10 }}>{challenge.name}</span>
          )}
        </div>
        {loading ? (
          <div />
        ) : (
          <div style={{ gap: 4 }}>
            {canManageChallenge || util.canViewEntireChallenge(user, challenge) ? (
              <>
                {!theme?.sizes?.isMobile ? (
                  <React.Fragment>
                    {window.location.pathname.match(/\/challenges\/[a-zA-Z0-9-]+\/settings/) && (
                      <Button
                        size="tiny"
                        content={t("challenge.settings.viewChallenge")}
                        as={Link}
                        to={`/challenges/${challenge._id}`}
                        icon="arrow left"
                        inverted
                        basic
                      />
                    )}
                    {!window.location.pathname.match(/\/challenges\/[a-zA-Z0-9-]+\/settings/) && (
                      <Button
                        size="tiny"
                        icon="cogs"
                        inverted
                        basic
                        content={t("challenge.settings.title")}
                        as={Link}
                        to={`/challenges/${challenge._id}/settings`}
                      />
                    )}
                    {!window.location.pathname.match(/\/challenges\/[a-zA-Z0-9-]+\/format/) && canManageChallenge && (
                      <Button
                        size="tiny"
                        icon="pencil"
                        inverted
                        basic
                        content={t("challenge.editFormat")}
                        as={Link}
                        to={`/challenges/${challenge._id}/settings/format`}
                      />
                    )}
                    {canManageChallenge && (
                      <>
                        {challenge.stage !== "published" && !canPublishChallenges ? (
                          <Button
                            size="tiny"
                            primary
                            disabled={requestSent}
                            icon={requestSent ? "check" : undefined}
                            content={t("challenge.stages.draft.request" + (requestSent ? "Sent" : ""))}
                            onClick={() => setRequestToOpenChallengeOpen(true)}
                          />
                        ) : (
                          <ChallengeStageChanger
                            challengeId={challengeId}
                            trigger={updateStageButton}
                            updateStage={updateStage}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                ) : (
                  <Dropdown icon={null} direction="left" trigger={<Button basic inverted icon="cogs" size="tiny" />}>
                    <Dropdown.Menu>
                      {!window.location.pathname.match(/\/challenges\/[a-zA-Z0-9-]+\/format/) && canManageChallenge && (
                        <Dropdown.Item
                          icon="pencil"
                          content={t("challenge.editFormat")}
                          as={Link}
                          to={`/challenges/${challenge._id}/settings/format`}
                        />
                      )}
                      {window.location.pathname.match(/\/challenges\/[a-zA-Z0-9-]+\/settings/) && (
                        <Dropdown.Item
                          icon="arrow left"
                          content={t("challenge.settings.viewChallenge")}
                          as={Link}
                          to={`/challenges/${challenge._id}`}
                        />
                      )}
                      {!window.location.pathname.match(/\/challenges\/[a-zA-Z0-9-]+\/settings/) && (
                        <Dropdown.Item
                          icon="cogs"
                          content={t("challenge.settings.title")}
                          as={Link}
                          to={`/challenges/${challenge._id}/settings`}
                        />
                      )}
                      {canManageChallenge && (
                        <>
                          <Dropdown.Item
                            icon="thumbtack"
                            content={
                              challenge.isPinned ? `Un-pin ${t("generic.challenge")}` : `Pin ${t("generic.challenge")}`
                            }
                            onClick={pinChallenge}
                          />
                        </>
                      )}
                      {canPublishChallenges && canPublishChallenges ? (
                        <>
                          {!challenge.stage && (
                            <Dropdown.Item
                              content={t("challenge.stages.draft.open")}
                              onClick={() => updateStage("published")}
                            />
                          )}
                          {challenge.stage === "published" && (
                            <Dropdown.Item
                              content={t("challenge.stages.published.close")}
                              onClick={() => updateStage("closed")}
                            />
                          )}
                          {challenge.stage === "closed" && (
                            <Dropdown.Item
                              content={t("challenge.stages.closed.reOpen")}
                              onClick={() => updateStage("published")}
                            />
                          )}
                        </>
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            ) : (
              !theme?.sizes?.isMobile &&
              overviewPage && (
                <div>
                  {challenge?.stage === "published" && !user ? (
                    <>
                      {(!myUnsubmittedIdeas || myUnsubmittedIdeas?.length === 0) && canMakeIdea ? (
                        <Button
                          size="tiny"
                          primary
                          icon="plus"
                          content={
                            myIdeas?.length === 0
                              ? challenge.buttonText
                                ? challenge.buttonText
                                : t("challenge.ideas.createYourIdea")
                              : t("challenge.startNew")
                          }
                          onClick={canMakeIdea ? newIdea : () => redirectToLogin()} // If public idea creation is enabled a user does not need to be logged in
                        />
                      ) : null}

                      {myUnsubmittedIdeas?.length === 1 && (
                        <Button
                          size="tiny"
                          icon="lightbulb"
                          content={t("challenge.continueIdea")}
                          primary
                          as={Link}
                          to={`/ideas/${myIdeas[0]._id}`}
                        />
                      )}

                      {myUnsubmittedIdeas?.length > 1 && (
                        <Dropdown
                          icon={null}
                          trigger={
                            <Button
                              size="tiny"
                              style={{ width: "100%" }}
                              primary
                              icon="lightbulb"
                              content={t("challenge.continueIdea")}
                            />
                          }
                        >
                          <Dropdown.Menu>
                            {myIdeas.map((i) => (
                              <Dropdown.Item key={i_id} as={Link} to={`/ideas/${i._id}`} content={i.name} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </>
                  ) : null}
                </div>
              )
            )}
          </div>
        )}
      </StyledStatusBar>
    </Sticky>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (id, challenge) => dispatch(actions.challenges.update(id, challenge)),
});
const mapStateToProps = (state, props) => ({ user: props?.isPreview ? null : state.user });
const ChallengeStatusBarContainer = connect(mapStateToProps, mapDispatchToProps)(ChallengeStatusBar);

export default withTranslation()(ChallengeStatusBarContainer);
