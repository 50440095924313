import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Message, Divider, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Other } from "simplydo/interfaces";
import { useAppSelector } from "store";
import api from "api";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

type ChallengeProblemInfo = Other.IProblemInfo;

type ChallengeProblemsProps = {
  challenge: Other.IChallenge;
};

const ChallengeProblems = ({ challenge }: ChallengeProblemsProps) => {
  const [challengeProblemInfo, setChallengeProblemInfo] = useState<ChallengeProblemInfo | null>(null);
  const user: Other.IUser = useAppSelector((state) => state.user);
  const challengeId = challenge._id;

  const canManage = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);

  const { t } = useTranslation();

  const getProblems = useCallback(() => {
    if (challengeId && canManage) {
      api.challenges.getProblems(
        challengeId,
        (data) => {
          setChallengeProblemInfo(data);
        },
        () => {},
      );
    }
  }, [canManage, challengeId]);

  useEffect(() => {
    getProblems();
  }, [getProblems]);

  if (challengeProblemInfo?.usersNotInAudience || challengeProblemInfo?.ipManagementMissingStatement) {
    return (
      <Message error icon>
        <Icon name="warning sign" />
        <Message.Content>
          {challengeProblemInfo?.ipManagementMissingStatement && (
            <>
              <Message.Header style={{ marginBottom: 10 }}>IP management misconfiguration</Message.Header>
              <Message.Content>
                This {t("generic.challenge")} has IP management enabled but does not have an IP statement configured.
                This means that users will be unable to create ideas for this {t("generic.challenge")}. Please disable
                this setting or configure an IP statement.
                <Divider hidden />
              </Message.Content>
            </>
          )}
          {challengeProblemInfo?.usersNotInAudience && (
            <>
              <Message.Header style={{ marginBottom: 10 }}>
                Audience misconfiguration:{" "}
                {util.pluralise(
                  challengeProblemInfo.usersNotInAudience.assessors.length +
                    challengeProblemInfo.usersNotInAudience.assignees.length,
                  "user",
                  "users",
                )}{" "}
                cannot currently view this {t("generic.challenge")}
              </Message.Header>
              <Message.Content>
                This {t("generic.challenge")} has assignees or assessors that aren't currently in the audience.{" "}
                {t("common:capitalise", { key: "generic.idea" })} assignees or assessors not in the{" "}
                {t("generic.challenge")}'s audience will not be able to view the
                {t("generic.challenge")} and may not be able to find their assigned ideas.
              </Message.Content>
              {challengeProblemInfo.usersNotInAudience.assessors.length > 0 ? (
                <>
                  <Message.Content style={{ marginTop: 10 }}>
                    The following assessors are not currently in the audience:{" "}
                    <b>
                      {challengeProblemInfo.usersNotInAudience.assessorUsers
                        .map((assessor) => assessor.profile.fullName)
                        .join(", ")}
                    </b>
                  </Message.Content>
                  <Divider hidden />
                </>
              ) : null}
              {challengeProblemInfo.usersNotInAudience.assignees.length > 0 ? (
                <>
                  <Message.Content style={{ marginTop: 10 }}>
                    The following project assignees are not currently in the audience:{" "}
                    <b>
                      {challengeProblemInfo.usersNotInAudience.assigneeUsers
                        .map((assignee) => assignee.profile.fullName)
                        .join(", ")}
                    </b>
                  </Message.Content>
                  <Divider hidden />
                </>
              ) : null}
            </>
          )}
          <Link to={`/challenges/${challengeId}/settings`}>
            Click here to configure the {t("generic.challenge")} settings
          </Link>
        </Message.Content>
      </Message>
    );
  }
  return null;
};

export default ChallengeProblems;
