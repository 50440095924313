import React, { useState, useRef, useEffect, CSSProperties, useMemo } from "react";

import { Table, Icon, Popup, Button, Message } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import api from "api";
import ReduxActions from "actions";
import { Other } from "simplydo/interfaces";
import useTheme from "theme/useTheme";

import { LargeCheckbox } from "components/lib/UI";
import TableSettings from "./TableSettings";
import ActionDropdown, { ActionDropdownProps } from "./ActionDropdown";
import DataRow from "./DataRow";
import { Column, ColumnOption, ColumnSetting } from "./types";

const actionKey = "__actions";
const minCellWidth = 75;

export type Columns<ItemType> = ColumnOption<ItemType>[];

export type ConfigurableTableProps<ItemType, T extends keyof ItemType = keyof ItemType> = {
  keyExtractor?: (item: ItemType) => ItemType[T];
  tableKey: string;
  data: ItemType[];
  columns: Columns<ItemType>;
  onRowClick?: (item: ItemType, event: Event) => void;
  getRowStyle?: (item: ItemType) => CSSProperties;
  getCellStyle?: (cell: ItemType[keyof ItemType], item: ItemType) => CSSProperties;
  onSelect?: (item: ItemType | null, checked: boolean) => void;
  preventSelectAll?: boolean;
  selectedKeys?: ItemType[T][];
  draggable?: boolean;
  actions?: ActionDropdownProps<ItemType>["actions"];
  sort?: { key: keyof ItemType; direction: "asc" | "desc" };
  onSort?: (key: keyof ItemType) => void;
  stackable?: boolean;
  wrapperStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  tableStyle?: CSSProperties;
  basic?: boolean;
  headerStyle?: CSSProperties;
  informationRow?: () => JSX.Element;
  handleInformationRowCLick?: () => void;
};

const TableWrapper = styled.div`
  margin-top: ${({ theme }) => (theme.sizes.isMobile ? "10px" : "0")};
  align-self: stretch;
  margin-bottom: 10px;
  position: relative;
  max-width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type ScrollState = {
  scrollable: boolean;
  atStart: boolean;
  atEnd: boolean;
};

const OverflowContainer = styled.div<{ $scrollState?: ScrollState }>`
  overflow: auto;
  border-radius: 4px;
  max-width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);

  .ui.table {
    border-radius: 4px;

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }

  table {
    ${({ $scrollState }) => {
      if (!$scrollState?.scrollable) {
        return "";
      }

      let boxShadow = `box-shadow:
        inset -10px 0 10px -5px rgb(0 0 0 / 15%),
        inset 10px 0 10px -5px rgb(0 0 0 / 15%);`;

      if ($scrollState?.atEnd) {
        boxShadow = `box-shadow:
          inset -10px 0 10px -5px rgb(0 0 0 / 0%),
          inset 10px 0 10px -5px rgb(0 0 0 / 15%);`;
      } else if ($scrollState?.atStart) {
        boxShadow = `box-shadow:
          inset -10px 0 10px -5px rgb(0 0 0 / 15%),
          inset 10px 0 10px -5px rgb(0 0 0 / 0%);`;
      }

      return `
        &::after {
          z-index: 300;
          content: "";
          width: 100%;
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 4px;
          pointer-events: none;
          transition: box-shadow 0.2s;
          ${boxShadow}
        }
      `;
    }}
  }
`;

const SettingsIcon = styled.div`
  z-index: 400;
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
  padding: 0 2px;
  border-radius: 4px;
  color: #767676;

  :hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const SizedHeaderCell = styled(Table.HeaderCell)<{ sortable?: boolean; $preferredWidth?: number }>`
  z-index: auto;
  ${({ $sortable }) =>
    $sortable &&
    `
    &&&&:hover {
      cursor: pointer;
      background: #e9e9e9;
    }
  `}

  ${({ $preferredWidth }) =>
    $preferredWidth
      ? `
    // Adjust for padding in table cells;
    min-width: calc(${$preferredWidth}px + 1.4em);
    width: calc(${$preferredWidth}px + 1.4em);
  `
      : `
    min-width: ${minCellWidth}px;
  `}
`;

const ConfigurableTable = <ItemType extends Record<string, any>>({
  tableKey,
  data,
  columns,
  onRowClick,
  sort,
  onSort,
  onSelect,
  preventSelectAll,
  actions,
  draggable,
  selectedKeys = [],
  keyExtractor = (item: ItemType) => item._id,
  wrapperStyle,
  containerStyle,
  tableStyle,
  basic,
  headerStyle,
  stackable,
  getRowStyle,
  getCellStyle,
  informationRow,
  handleInformationRowCLick,
}: ConfigurableTableProps<ItemType>) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [scrollState, setScrollState] = useState<ScrollState>({
    scrollable: false,
    atStart: true,
    atEnd: false,
  });

  const dispatch = useDispatch();
  const user = useSelector((state: { user: Other.IUserMe | null }) => state?.user);
  const savedColumns = useMemo(() => {
    if (!user) {
      return [];
    }
    const { tablePreferences = {} } = user;
    const { columns: userColumns = [] } = (tablePreferences ?? {})[tableKey] ?? {};
    return userColumns ?? [];
  }, [user, tableKey]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const effectElement = containerRef.current;
      const trackScroll = (e) => {
        const { scrollLeft, clientWidth } = e.target;
        const innerWidth = e.target.childNodes[0].clientWidth;

        const containerWidth = effectElement.clientWidth;
        const tableElement: HTMLTableElement = effectElement.childNodes[0] as HTMLTableElement;
        const tableWidth = tableElement.clientWidth;
        const scrollable = containerWidth < tableWidth;
        setScrollState((prev) => ({ ...prev, scrollable }));

        if (scrollLeft === 0) {
          setScrollState((prev) => ({ ...prev, atStart: true }));
        } else {
          setScrollState((prev) => ({ ...prev, atStart: false }));
        }

        if (innerWidth - clientWidth <= scrollLeft + 2) {
          setScrollState((prev) => ({ ...prev, atEnd: true }));
        } else {
          setScrollState((prev) => ({ ...prev, atEnd: false }));
        }
      };

      const containerWidth = effectElement.clientWidth;
      const tableElement: HTMLTableElement = effectElement.childNodes[0] as HTMLTableElement;
      const tableWidth = tableElement.clientWidth;
      const scrollable = containerWidth < tableWidth;
      setScrollState((prev) => ({ ...prev, scrollable }));

      effectElement.addEventListener("scroll", trackScroll);

      return () => {
        if (effectElement) {
          effectElement.removeEventListener("scroll", trackScroll);
        }
      };
    }
  }, []);

  const hasActions = useMemo(() => !!actions, [actions]);
  const columnSettings = useMemo(() => {
    let allColumns = columns;
    if (hasActions) {
      allColumns = [
        ...columns,
        {
          key: actionKey,
          name: "Actions dropdown",
          width: 75,
          center: true,
        },
      ];
    }

    return allColumns
      .map((column, i) => {
        const savedEntry = savedColumns.find((col) => col.key === column.key);
        return {
          ...column,
          name: column.name ?? column.key,
          enabled: savedEntry?.enabled ?? column.enabled ?? true,
          order: savedEntry?.order ?? i,
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [columns, hasActions, savedColumns]);
  const enabledColumns = useMemo(() => columnSettings.filter((col) => col.enabled), [columnSettings]);

  const theme = useTheme();
  const selectable = !!onSelect && !theme.sizes.isMobile;
  const selectedItems = useMemo(
    () => data.filter((item) => selectedKeys.includes(keyExtractor(item))),
    [data, selectedKeys, keyExtractor],
  );

  const updateColumns = (newColumns: Column<ItemType>[] | null) => {
    // Filter props to only save the ones we actually need in the db
    const saveableColumns: ColumnSetting<ItemType>[] | null =
      newColumns?.map((col) => ({
        key: col.key,
        enabled: col.enabled,
        order: col.order,
      })) ?? null;
    const prevSettings = { ...columnSettings };

    // Apply immediately locally, but revert to previous if the api call fails
    dispatch(ReduxActions.user.updateTablePreferences(tableKey, { columns: saveableColumns }));
    api.users.updateTablePreferences(
      user?._id,
      tableKey,
      { columns: saveableColumns },
      () => {},
      () => {
        dispatch(ReduxActions.user.updateTablePreferences(tableKey, { columns: prevSettings }));
      },
    );
  };

  const tableSettings = (
    <TableSettings
      open={settingsOpen}
      onClose={() => setSettingsOpen(false)}
      updateColumns={updateColumns}
      columns={columnSettings}
    />
  );

  if (enabledColumns.length === 0) {
    const text =
      data.length > 0
        ? `There are ${data.length} results but all columns are disabled so there is no data to show.`
        : "There are no results and all columns are disabled so there is no data to show.";

    return (
      <>
        {tableSettings}
        <Message icon warning>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>No columns enabled</Message.Header>
            <p>{text}</p>
            <Button onClick={() => setSettingsOpen(true)}>Edit table settings</Button>
          </Message.Content>
        </Message>
      </>
    );
  }
  return (
    <>
      {tableSettings}
      <TableWrapper style={wrapperStyle}>
        <Popup
          trigger={
            <SettingsIcon onClick={() => setSettingsOpen(true)}>
              <Icon size="small" name="cogs" color="grey" />
            </SettingsIcon>
          }
          content="Edit layout and change settings for this table."
        />
        <OverflowContainer ref={containerRef} $scrollState={scrollState} style={containerStyle}>
          <Table
            compact
            selectable={!!onRowClick}
            style={{ ...(tableStyle || {}), border: "none" }}
            stackable={stackable}
            unstackable={!stackable}
            basic={basic}
          >
            <Table.Header style={headerStyle}>
              <Table.Row>
                {selectable && !preventSelectAll ? (
                  <SizedHeaderCell $preferredWidth={40}>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LargeCheckbox
                        checked={selectedKeys.length > 0}
                        onChange={(e, checked) => {
                          e.preventDefault();
                          onSelect && onSelect(null, checked);
                        }}
                      />
                    </div>
                  </SizedHeaderCell>
                ) : (
                  <>{selectable ? <SizedHeaderCell $preferredWidth={40} /> : null}</>
                )}
                {enabledColumns.map((col) => (
                  <SizedHeaderCell
                    key={col.key}
                    $preferredWidth={col.width}
                    $sortable={col.sortable && onSort && sort}
                    onClick={() => col.sortable && onSort && onSort(col.key)}
                    collapsing={col.collapsing}
                    style={
                      col.key === actionKey
                        ? {
                            position: "sticky",
                            right: 0,
                            zIndex: 1,
                            background: "#f9fafb",
                            boxShadow: "-1px 0 1px #ddd",
                            minWidth: 100,
                          }
                        : {}
                    }
                  >
                    {col.key === actionKey ? (
                      selectable && <ActionDropdown actions={actions} items={selectedItems} type="multi" user={user} />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                          lineHeight: col?.compactTitle ? "1" : null,
                        }}
                      >
                        {col.name}
                        {col.sortable && sort && sort.key === col.key ? (
                          <Icon
                            name={`chevron circle ${sort.direction === "asc" ? "up" : "down"}`}
                            style={{ marginTop: -9 }}
                          />
                        ) : null}
                      </div>
                    )}
                  </SizedHeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {informationRow && (
                <Table.Row onClick={() => handleInformationRowCLick()}>
                  <Table.Cell colSpan={columns?.length}>
                    <Icon name="question circle" color="grey" />
                    {informationRow()}
                  </Table.Cell>
                </Table.Row>
              )}
              {data.map((item, index) => (
                <DataRow
                  index={index}
                  key={keyExtractor(item)}
                  selected={selectedKeys.includes(keyExtractor(item))}
                  selectable={selectable}
                  item={item}
                  enabledColumns={enabledColumns}
                  onSelect={onSelect}
                  onRowClick={onRowClick}
                  actions={actions}
                  user={user}
                  draggable={draggable}
                  getRowStyle={getRowStyle}
                  getCellStyle={getCellStyle}
                />
              ))}
            </Table.Body>
          </Table>
        </OverflowContainer>
      </TableWrapper>
    </>
  );
};

export default React.memo(ConfigurableTable);
