import React, { useCallback, useState, useMemo } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import Planner from "./Planner";
import util from "utils/utils";
import Settings from "./Settings";
import api from "api";
import { useDidMount } from "utils/useDidMount";
import { toast } from "react-hot-toast";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";

type CalendarResp = OpenAPI.GET<"/challenges/{id}/calendar">["response"];

const PlannerMenu = ({ challenge }) => {
  const [isLoading, setLoading] = useState(true);
  const [calendar, setCalendar] = useState<CalendarResp["calendar"]>(null);
  const challengeId = challenge._id;
  const user = useAppSelector((state) => state.user);

  const getCalendar = useCallback(() => {
    api.challenges.getCalendar(
      challengeId,
      ({ calendar }: CalendarResp) => {
        setCalendar(calendar);
        setLoading(false);
      },
      (error) => {
        toast.error(error.message);
      },
    );
  }, [challengeId]);

  useDidMount(() => {
    setLoading(true);
    getCalendar();
  });
  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);

  return (
    <div>
      <Menu pointing secondary>
        <Menu.Item active={util.activePath("/planner$")} icon="calendar" content="Planner" as={Link} to="." />
        {canManageChallenge && (
          <Menu.Item
            active={util.activePath("/planner/settings")}
            icon="cogs"
            content="Settings"
            as={Link}
            to="settings"
          />
        )}
      </Menu>

      <Routes>
        {canManageChallenge && (
          <Route
            path="settings"
            element={<Settings challenge={challenge} calendar={calendar} setCalendar={setCalendar} />}
          />
        )}
        <Route
          path="/"
          element={
            <Planner
              challenge={challenge}
              calendar={calendar}
              setCalendar={setCalendar}
              getCalendar={getCalendar}
              isLoading={isLoading}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default PlannerMenu;
