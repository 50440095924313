import React, { useCallback, useState, useMemo } from "react";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import { Button, Icon } from "semantic-ui-react";
import actions from "actions";
import { withTranslation } from "react-i18next";
import useTheme from "theme/useTheme";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserReload } from "utils/useUserReload";

const GroupJoinButton = ({ t, user, group, onGroupLeft, getGroup, isCardView }) => {
  const isInGroup = util.isInGroup(user, group);
  const [requested, setRequested] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const groupId = group?._id;
  const userId = user?._id;
  const theme = useTheme();
  const navigate = useNavigate();

  const reloadUser = useUserReload();

  const canAccessGroup = useMemo(
    () =>
      group &&
      user &&
      (group.organisation === user.organisation._id ||
        (user?.availableOrganisations?.map((org) => org._id) ?? []).indexOf(group.organisation) > -1),
    [group, user],
  );

  const joinGroup = useCallback(() => {
    api.groups.join(
      groupId,
      userId,
      () => {
        reloadUser(() => {
          navigate(`/groups/${groupId}`);
        });
      },
      (err) => toast.error(err.message),
    );
  }, [groupId, userId, reloadUser, navigate]);

  const leaveGroup = useCallback(() => {
    // eslint-disable-next-line no-nested-ternary
    util
      .confirm(
        t("groups.leave.title"),
        !canAccessGroup
          ? "This group is not in your organisation, so you will be unable to join again unless you are invited by a group leader."
          : group.isClosed
            ? t("groups.leave.infoClosed")
            : t("groups.leave.infoOpen"),
      )
      .then(() => {
        api.groups.leave(
          group._id,
          userId,
          () => {
            onGroupLeft(group);
            navigate("/groups");
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  }, [t, group, canAccessGroup, userId, onGroupLeft, navigate]);

  const requestToJoinGroup = useCallback(() => {
    setRequesting(true);
    api.invitations.create(
      {
        forId: groupId,
        forType: "requestJoinGroup",
        invitee: userId,
      },
      () => {
        setRequesting(false);
        toast.success("Request to join group created");
        setRequested(true);
        if (isCardView) {
          navigate(`/groups/${groupId}`);
        } else {
          getGroup();
        }
      },
      (err) => toast.error(err.message),
    );
  }, [userId, groupId, getGroup, navigate, isCardView]);

  if (group.isInvited) {
    return (
      <p
        style={{ color: "#fff", cursor: "pointer" }}
        onClick={() => {
          navigate(`/groups/${groupId}`);
        }}
      >
        {t("invitations.redirect.title")} <Icon name="arrow alternate circle right outline" />
      </p>
    );
  }

  if (group.isClosed && !isInGroup && !util.canManageGroup(user, group)) return null;
  if (group.requestToJoin && !isInGroup && !util.canManageGroup(user, group) && canAccessGroup) {
    if (!isCardView) {
      return (
        <Button
          primary
          size={theme.sizes.isMobile ? "tiny" : null}
          loading={requesting}
          disabled={requested || group.requestedToJoin}
          icon="paper plane"
          content={group.requestedToJoin ? t("groups.requestedToJoin") : t("groups.requestToJoinGroup")}
          onClick={requestToJoinGroup}
        />
      );
    }
    return (
      <p
        style={{ color: "#fff", cursor: "pointer" }}
        onClick={
          group.requestedToJoin
            ? () => {
                navigate(`/groups/${groupId}`);
              }
            : requestToJoinGroup
        }
      >
        {(group.requestedToJoin ? t("groups.requestedToJoinShort") : t("groups.requestToJoinGroup")).toUpperCase()}{" "}
        <Icon name="paper plane" />
      </p>
    );
  }
  if (!isCardView) {
    if (isInGroup && !canAccessGroup) {
      return <Button icon="user delete" onClick={leaveGroup} content={t("groups.leaveGroup")} />;
    }
    if (canAccessGroup) {
      return (
        <Button
          primary={!isInGroup}
          icon={isInGroup ? "user delete" : "user plus"}
          onClick={isInGroup ? leaveGroup : joinGroup}
          content={isInGroup ? t("groups.leaveGroup") : t("groups.joinGroup")}
        />
      );
    }
    return null;
  }
  if (!canAccessGroup) return null;
  return (
    <p
      style={{ color: "#fff", cursor: "pointer" }}
      onClick={
        isInGroup
          ? () => {
              navigate(`/groups/${groupId}`);
            }
          : joinGroup
      }
    >
      {(isInGroup ? t("generic.view", { entity: "Group" }) : t("groups.joinGroup")).toUpperCase()}{" "}
      <Icon name={isInGroup ? "arrow alternate circle right outline" : "user plus"} />
    </p>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  onGroupLeft: (group) => dispatch(actions.user.leaveGroup(group)),
});

const GroupJoinButtonContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GroupJoinButton));

export default GroupJoinButtonContainer;
